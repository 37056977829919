<template>
  <div class="d-flex-column align-center main rel-wrap">
    <el-form label-position="top" class="main full-width input-underline">
      <el-card>
        <div class="mw-400">
          <h3 class="head mt-0">Clearance Form</h3>
          <p>Please complete the below form to change clubs.</p>
          <div class="main" :key="componentKey">
            <SuperInput
              disabled
              :field="form.firstName"
              v-model="form.firstName.value"
              :errors.sync="form.firstName.errors"
            />
            <SuperInput
              disabled
              :field="form.lastName"
              v-model="form.lastName.value"
              :errors.sync="form.lastName.errors"
            />
            <SuperInput
              disabled
              :field="form.dob"
              v-model="form.dob.value"
              :errors.sync="form.dob.errors"
            />
            <SuperInput
              :field="form.reason"
              v-model="form.reason.value"
              :errors.sync="form.reason.errors"
            />
            <SuperInput
              :field="form.reasonText"
              v-model="form.reasonText.value"
              :errors.sync="form.reasonText.errors"
            />
          </div>
        </div>
        <div class="d-flex-column main mw-400 mb-2">
          <el-button type="primary" class="full-width" @click="submitForm">
            Continue
          </el-button>
        </div>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import get from "lodash/get";
import SuperInput from "@/components/form/fields/SuperInput.vue";
import { errorOrPass, makeForm, populate } from "@/utils/forms";
import endpoints from "@/utils/constants/api";
import msg from "@/utils/constants/msg";

export default {
  name: "ClearanceForm",
  components: {
    SuperInput
  },
  props: ["memberId", "memberType", "toClubId"],
  computed: {
    ...mapGetters("registration", ["current"])
  },
  mounted() {
    const participantForm = this.current ? get(this.current, "profile.participantForm") : false;
    this.form = populate(this.form, participantForm);
    this.componentKey += 1;
  },
  methods: {
    ...mapMutations("root", ["LOADING"]),
    ...mapActions("views", ["notify"]),
    async submitForm() {
      // Validate form
      if (!errorOrPass(this.form, null, this, window)) return;

      // get real reason
      const reason =
        this.form.reason.value === "Other" ? this.form.reasonText.value : this.form.reason.value;

      this.LOADING(true);
      // Normalize date pre-send
      const { memberId, memberType, toClubId } = this;

      try {
        await this.$http.post(endpoints.clearance, {
          memberId: Number(memberId),
          memberType,
          toClubId: Number(toClubId),
          reason
        });
        this.LOADING(false);
        this.$router.push({ name: "clearancecompleted" }).catch(() => {});
      } catch (error) {
        this.notify({
          autoClose: true,
          msg:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : msg.error.apiError,
          type: "warning",
          window
        });
        this.LOADING(false);
      }
    }
  },
  data() {
    return {
      componentKey: 0,
      form: makeForm({
        firstName: { label: "First Name" },
        lastName: { label: "Last Name" },
        dob: { component: "DateInput", label: "Birthday" },
        reason: {
          label: "Why are you changing teams?",
          component: "SelectSP",
          options: [
            "I am moving from a Junior to a Senior Club",
            "I have relocated and no longer live near my previous Club",
            "I would like to try a new Club",
            "Other"
          ].map(x => ({ label: x, value: x }))
        },
        reasonText: {
          label: "Additional reason",
          displayed: () => this.form.reason.value === "Other"
        }
      })
    };
  }
};
</script>

<style lang="scss" scoped>
.head {
  font-family: $fontMedium, Arial;
}

p {
  font-size: 0.875rem;
  font-family: $fontMedium;
}
</style>
